.reportList-container {
    text-align: left;
    min-height: calc(100vh - 60px);
    overflow: auto;
    position: relative;
    padding-top: 60px;
}

.reportList-title {
    font-size: 25px;
    font-weight: 400;
    color: rgba(0,0,0,0.70);
    margin-top: 50px; 
}

.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before, .s-alert-close::after {
    width: 2px;
}

.app-body{
    margin-left: 240px;
}